import { render, staticRenderFns } from "./investor.vue?vue&type=template&id=c6f28630&scoped=true"
import script from "./investor.vue?vue&type=script&lang=js"
export * from "./investor.vue?vue&type=script&lang=js"
import style0 from "./investor.vue?vue&type=style&index=0&id=c6f28630&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6f28630",
  null
  
)

component.options.__file = "investor.vue"
export default component.exports