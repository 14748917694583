export function cartesianProduct(...sets) {
  console.log(sets, 2);
  // console.log("sets", sets);
  if (sets.length === 0) return [[]]; // 处理空集合的情况

  // 判空

  // 使用递归调用来处理多个集合
  const [firstSet, ...remainingSets] = sets;

  if (firstSet.length === 0) return [[]];
  const productOfRemaining = cartesianProduct(...remainingSets);

  const product = [];

  // 遍历第一个集合的元素，并与后续集合的笛卡尔积组合
  for (let element of firstSet) {
    for (let productOfRemainingElement of productOfRemaining) {
      product.push([element, ...productOfRemainingElement]);
    }
  }

  return product;
}

export function rowMergeHandle(arr, data) {
  if (!Array.isArray(arr) && !arr.length) return false;
  if (!Array.isArray(data) && !data.length) return false;
  let needMerge = {}; // 需要合并的对象
  arr.forEach((i, idx) => {
    needMerge[i] = {
      // 初始化需要合并的对象
      rowArr: [], // 合并行数
      rowMergeNum: 0 // 合并行数
    };
    if (idx == 0) {
      data.forEach((item, index) => {
        // 表格首个数据单独处理
        if (index === 0) {
          //
          needMerge[i].rowArr.push(1); // 非合并行数
          needMerge[i].rowMergeNum = 0; // 合并行数
        } else {
          if (item[i] === data[index - 1][i]) {
            needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1; // 合并行数
            needMerge[i].rowArr.push(0); // 非合并行数
          } else {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = index;
          }
        }
      });
    } else {
      let firstRowArr = needMerge[arr[0]].rowArr;
      let firstRowArrDeal = [];
      firstRowArr.forEach((item, index) => {
        if (item > 0) {
          firstRowArrDeal.push(index);
        }
      });
      data.forEach((item, index) => {
        let sign = false;
        if (firstRowArrDeal.indexOf(index) > 0) {
          needMerge[i].rowMergeNum = index;
          sign = true;
        }
        // 表格首个数据单独处理
        if (index === 0) {
          needMerge[i].rowArr.push(1);
          needMerge[i].rowMergeNum = 0;
        } else {
          if (item[i] === data[index - 1][i]) {
            if (sign) {
              needMerge[i].rowArr.push(1);
            } else {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            }
          } else {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = index;
          }
        }
      });
    }
  });
  return needMerge;
}



// // 示例集合
const setA = ["国网华远"];
const setB = [
  { name: "张三", age: 25 },
  { name: "李四", age: 30 }
];
// const setC = ["合肥市","芜湖市"];
// const setD = [620,720,620,720];
// const setF = ["常规阵列","南北破","阳光房"]
// const setE = ["项目公司","项目公司","项目公司"]

// // // 生成笛卡尔积
// const result = cartesianProduct(setA, setB,setC,setD,setE,setF);
// console.log(result);
