<template>
  <div class="employeeDetail positionr" v-loading="loading">
    <!-- 头像更换 -->
    <div class="avatar-uploader positiona"></div>

    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="4">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="20">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <!-- <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('ruleForm')"
                            >保存</el-button
                          > -->
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="150px"
                  class="ruleForm"
                  size="mini"
                >
                  <el-row style="display: flex; flex-wrap: wrap">
                    <el-col :sm="24" :md="24" :lg="24">
                      <el-form-item label="代理商描述">
                        <el-input
                          v-model="ruleForm.description"
                          placeholder="请输入"
                          :rows="3"
                          type="textarea"
                          class="wp100"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>

            <!-- 项目所属公司（投资方） -->
            <div class="p1" id="a2">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span>
                          <span>项目所属公司（投资方）</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="addInvestor"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="postTableData"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="公司名称"
                    align="center"
                    prop="business_name"
                  ></el-table-column>
                  <el-table-column
                    label="负责人"
                    align="center"
                    prop="head"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="del(scope.$index, 'postTableData')"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 项目所属区域 -->
            <div class="p1" id="a3">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>项目所属区域</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="setselectPostDialog"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <div
                  class="quyu"
                  v-for="(item, index) in regionTages"
                  :key="index"
                >
                  <div>
                    {{ "区域" + (index + 1) }}
                  </div>
                  <div class="shengfen">
                    <el-row>
                      <el-col :span="2">所在省份：</el-col>
                      <el-col :span="20"
                        ><el-tag
                          :key="tag"
                          v-for="tag in item.agencyAreaOutList"
                          closable
                          :disable-transitions="false"
                          @close="handleClose(tag)"
                        >
                          {{ tag }}
                        </el-tag></el-col
                      >
                    </el-row>
                  </div>
                  <div class="shiqu">
                    <el-row>
                      <el-col :span="2">所在城市：</el-col>
                      <el-col :span="20"
                        ><el-tag
                          :key="tag"
                          v-for="tag in item.cityItemOutList"
                          closable
                          style="margin-right: 5px"
                          :disable-transitions="false"
                          @close="handleClose(tag)"
                        >
                          {{ tag }}
                        </el-tag></el-col
                      >
                    </el-row>
                  </div>
                </div>
              </div>
            </div>

            <!-- 项目安装方式 -->
            <div class="p1" id="a4">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>项目安装方式</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="addInstallation"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="InstallationList"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="安装方式"
                    align="center"
                    prop="followway"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="del(scope.$index, 'InstallationList')"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 项目备案模式 -->
            <div class="p1" id="a5">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>项目备案模式</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="addFiling"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="FilingList"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="备案模式名称"
                    align="center"
                    prop="icpmode"
                  ></el-table-column>
                  <el-table-column
                    label="备案模式描述"
                    align="center"
                    prop="head"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="del(scope.$index, 'FilingList')"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 物料信息 -->
            <div class="p1" id="a6">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>物料信息</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="addMaterial"
                          ><i class="el-icon-plus fw900 f16"></i>
                          新增</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="materialList"
                  border
                  fit
                  height="240px"
                  highlight-current-row
                  style="width: 100%"
                >
                  <el-table-column
                    label="物料类型"
                    align="center"
                    prop="specs"
                  ></el-table-column>
                  <el-table-column
                    label="物料名称"
                    align="center"
                    prop="productName"
                  ></el-table-column>
                  <el-table-column
                    label="物料型号"
                    align="center"
                    prop="brand"
                  ></el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    class-name="small-padding"
                    fixed="right"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="bottom"
                      >
                        <i
                          class="el-icon-close textblue f16 fw700"
                          @click="del(scope.$index, 'materialList')"
                        ></i>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 基础结算价格 -->
            <div class="p1" id="a6">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>基础结算价格</span>
                        </div>
                        <el-button
                          plain
                          class="add-column-btn"
                          @click="excelExport"
                          ><i class="el-icon-plus fw900 f16"></i>
                          导出</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div>
                  <el-row>
                    <el-col :span="12">
                      <span>政策有效期</span>
                      <el-date-picker
                        v-model="policyDate"
                        type="datetimerange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd"
                        :default-time="['00:00:00', '23:59:59']"
                        @change="changeDate"
                      >
                      </el-date-picker>
                    </el-col>
                    <el-col :span="12" style="text-align: end">
                      <el-button type="primary" @click="savePrice"
                        >保 &emsp;存</el-button
                      >
                      <el-button type="primary" @click="saveTakeEffect"
                        >保存并生效</el-button
                      >
                      <el-button type="primary" @click="handelHistory"
                        >历史记录</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-row>
                  <el-form> </el-form>
                  <el-col> </el-col>
                </el-row>
                <!-- 表格 -->
                <el-table
                  v-loading="loading"
                  :data="priceList"
                  border
                  style="width: 100%"
                  :span-method="objectSpanMethod"
                  id="pdf"
                >
                  <el-table-column
                    label="投资方"
                    align="center"
                    prop="investor"
                  ></el-table-column>
                  <el-table-column
                    label="省份"
                    align="center"
                    prop="province"
                  ></el-table-column>
                  <el-table-column
                    label="区域"
                    align="center"
                    prop="area"
                  ></el-table-column>
                  <el-table-column
                    label="组件型号"
                    align="center"
                    prop="partsModel"
                  ></el-table-column>
                  <el-table-column
                    label="安装模式"
                    align="center"
                    prop="followway"
                  ></el-table-column>
                  <el-table-column
                    label="备案模式"
                    align="center"
                    prop="icpmode"
                  ></el-table-column>
                  <el-table-column
                    label="基础价格"
                    align="center"
                    prop="baseprice"
                  >
                    <template v-slot="{ row }">
                      <el-input
                        type="text"
                        v-model="row.baseprice"
                        style="width: 100px"
                      />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 结算比例 -->
            <div class="p1" id="a6">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="24">
                      <div class="flexCenter">
                        <div>
                          <span class="line"></span> <span>结算比例</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-row>
                  <el-form label-width="90px">
                    <el-row>
                      <el-col :span="6">
                        <el-form-item label="报装完成款">
                          <div style="display: flex">
                            <el-input
                              v-model="formScale.name"
                              placeholder="请输入"
                              clearable
                            /><span> %</span>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-row>
                      <el-col :span="6">
                        <el-form-item label="并网款">
                          <div style="display: flex">
                            <el-input
                              v-model="formScale.aee"
                              placeholder="请输入"
                              clearable
                              disabled
                            /><span> 元</span>
                          </div>
                        </el-form-item>
                      </el-col>
                    </el-row>

                    <el-col :span="6">
                      <el-form-item label="质保金">
                        <div style="display: flex">
                          <el-input
                            v-model="formScale.add"
                            placeholder="请输入"
                            clearable
                          /><span> 瓦</span>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-form>
                  <el-col> </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- 弹窗区域 -->
      <!-- 项目所属公司（投资方） -->
      <investor ref="investorFef" @toInvestor="getInvestor"></investor>
      <!--  项目所属区域  -->
      <el-dialog
        title="项目所属区域"
        :visible.sync="selectPostDialog"
        class="selectorDialog"
      >
        <div class="org-selected">
          <el-row>
            <el-col :span="12">
              <div class="pl10 pr10 bbox">
                <div class="">
                  <el-radio-group v-model="agencyAreaItem" @change="getCity">
                    <div
                      class="checkboxItem"
                      v-for="item in agencyArea"
                      :key="item.id"
                    >
                      <el-radio :label="item">{{ item.name }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="pl10 pr10 bbox">
                <div class="">
                  <el-radio-group v-model="cityItem" @change="getCounty">
                    <div
                      class="checkboxItem"
                      v-for="(item, index) in cityList"
                      :key="item.postCode"
                    >
                      <el-radio :label="item">{{ item.name }}</el-radio>
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirmPost">确 认</el-button>
          <el-button @click="selectPostDialog = false">取 消</el-button>
        </div>
      </el-dialog>
      <material ref="materialRef" @toMaterial="getMaterial"></material>
      <!-- 项目安装方式 -->
      <installation-mode
        ref="modeRef"
        @toInstallation="getInstallation"
      ></installation-mode>
      <!-- 项目备案模式 -->
      <filing-mode ref="filingRef" @toFiling="getFiling"></filing-mode>
      <!-- 历史记录 -->
      <el-dialog title="历史记录" :visible.sync="historyDialog" width="80%">
        <div class="history">
          <div class="left">
            <div v-for="item in historyList" :key="item">
              <div
                :class="currentData == item ? 'Datebox active' : 'Datebox'"
                @click="changeHistoryDate(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <div class="right">
            <el-table
              v-loading="loading"
              :data="historyTableList"
              border
              style="width: 100%"
              :span-method="objectSpanMethod"
            >
              <el-table-column
                label="投资方"
                align="center"
                prop="investor"
              ></el-table-column>
              <el-table-column
                label="省份"
                align="center"
                prop="province"
              ></el-table-column>
              <el-table-column
                label="区域"
                align="center"
                prop="area"
              ></el-table-column>
              <el-table-column
                label="组件型号"
                align="center"
                prop="partsModel"
              ></el-table-column>
              <el-table-column
                label="安装模式"
                align="center"
                prop="followway"
              ></el-table-column>
              <el-table-column
                label="备案模式"
                align="center"
                prop="icpmode"
              ></el-table-column>
              <el-table-column label="基础价格" align="center" prop="baseprice">
                <template v-slot="{ row }">
                  <el-input
                    type="text"
                    v-model="row.baseprice"
                    style="width: 100px"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="historyDialog = false">取 消</el-button>
          <el-button type="primary" @click="historyDialog = false"
            >确 定</el-button
          >
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import investor from "./dialog/scheme/investor";
import installationMode from "./dialog/scheme/installationMode";
import filingMode from "./dialog/scheme/filingMode";
import material from "./dialog/scheme/material";
import { cartesianProduct, rowMergeHandle } from "../../assets/js/table";
export default {
  components: { investor, installationMode, filingMode, material },
  data() {
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error("请输入合法的手机号码"));
    };
    return {
      parentCode: "",
      talkType: "add",
      talkTotal: 0,
      policyDate: "", // 政策时间
      talkQueryInfo: {
        currPage: 1,
        pageSize: 20,
        condition: {
          userCode: this.$route.query.userCode,
        },
      },

      talkTable: [],
      dialogVisibleInner: false,
      dialogImageUrl: "",

      fileList: [],
      talkForm: {
        id: "",
        userCode: "",
        reward: "",
        talkor: "",
        content: "",
        talkDt: "",
      },
      talkDialog: true,
      seeThis: 0,
      formScale: {
        name: "",
        add: "",
      },
      tabs: [
        "基本信息",
        "项目所属公司（投资方）",
        "项目所属区域",
        "项目安装方式",
        "项目备案模式",
        "物料信息",
      ], //  // "扩展属性",
      // "汇报关系",
      ruleForm: {
        id: "",
        name: "",
        phone: "",
        ad: "",
        sex: "1",
        status: 1,
        employeeType: 1,
        escapContacts: "",
        maritalStatus: "",
        restType: 1,
        signNotice: 1,
        description: "",
      },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        ad: [{ required: true, message: "员工编号不能为空", trigger: "blur" }],
      },
      tableData: [],
      id: "",
      userCode: "",
      disabledFalg: false,
      loading: true,
      imageUrl: "",
      userName: "",
      orgTableData: [], //与组织的关系表格数据
      treeData: [], // 组织树形结构数据
      defaultProps: {
        children: "childrens",
        label: "deptName",
      },
      defaultKyes: [], //选中节点
      defaultId: "", //当前节点
      multipleSelection: [], // 组织选中列表
      selectOrgDialog: true, //选择组织弹窗
      postTableData: [], //与岗位的关系表格数据
      selectPostDialog: false, // 选择岗位弹窗
      selectPostData: [], // 岗位信息表格数据
      multipleSelection2: [], // 岗位选中列表
      checkAll: false, //全选状态
      checkedItems: [], // 默认选中
      isIndeterminate: true, //表示 checkbox 的不确定状态，一般用于实现全选的效果
      queryInfoPost: {
        // 当前页数
        page: 1,
        // 每页显示多少数据
        size: 20,
      },
      totlePost: "",
      roleTableData: [], // 与权限的关系表格数据
      selectAuthorityDialog: true, //选择权限弹窗
      selectAuthorityData: [], // 选择权限列表
      multipleSelection3: [], // 权限选中列表

      InstallationList: [], // 项目安装方式列表
      FilingList: [], // 项目备案模式
      materialList: [], // 物料名称列表

      queryInfoAuthority: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 100,
      },
      totleAuthority: "",

      agencyArea: [], // 项目所属区域省份
      agencyAreaItem: {},
      cityItem: {},
      cityList: [], // 项目所属城市
      countyItem: {},
      countyList: [],
      regionTages: [], // 区域块
      provinceTags: [],
      cityTags: [],
      regionList: [],
      // setA: [], // 项目所属子公司（投资方）
      setB: [], // 项目所属区域
      setC: [], // 项目所属城市
      // setD: [], // 物料信息
      // setE: [], // 项目安装方式
      // setF: [], // 项目备案模式
      priceList: [], //价格方案
      needMergeArr: [
        "investor",
        "province",
        "area",
        "partsModel",
        "followway",
        "icpmode",
      ],
      rowMergeArrs: [],
      value1: [],
      effectivetime: "", // 开始时间
      expirationtime: "",
      historyDialog: false,
      historyList: [], // 历史记录数组
      currentData: "", // 默认第一个
      historyTableList: [], //历史记录表格数据
    };
  },
  // watch: {
  //   "talkQueryInfo.condition.useCode": {
  //     handler(newValue, oldValue) {
  //       this.getTalkTable();
  //     },
  //   },
  // },
  created() {
    this.id = this.$route.query.id;
    if (this.id == undefined || this.id == "") {
      this.userCode = this.randomString(11);
      this.disabledFalg = true;
    } else {
      this.userCode = this.$route.query.userCode;
      this.disabledFalg = false;
      this.getDataById();
    }

    this.userName = JSON.parse(localStorage.getItem("userInfo")).userInfo.name;
    this.getTreeData();
    this.getAddress();
    this.getRoleTableData();
    this.getTableData();
  },
  mounted() {
    var that = this;
    // this.getTalkTable();
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;

    // this.postTableData =
    //   JSON.parse(localStorage.getItem("postTableData")) || [];
    // this.regionTages = JSON.parse(localStorage.getItem("regionTages")) || [];
    // this.setB = this.regionTages.map((item) => item.agencyAreaOutList) || [];
    // this.setC = this.regionTages.map((item) => item.cityItemOutList) || [];
    // this.materialList = JSON.parse(localStorage.getItem("materialList")) || [];
    // this.InstallationList =
    //   JSON.parse(localStorage.getItem("InstallationList")) || [];
    // this.FilingList = JSON.parse(localStorage.getItem("FilingList")) || [];
    this.createTable();
  },

  methods: {
    createTable() {
      // this.getTableData();
      const agentCode = this.$route.query.agentCode;
      const agentName = this.$route.query.agentName;
      console.log(this.$route.query.agentCode, 859);
      const res = cartesianProduct(
        this.postTableData,
        this.setB,
        this.setC,
        this.materialList,
        this.InstallationList,
        this.FilingList
      );
      console.log(res, "res");
      this.priceList = res.map((item, index) => {
        return {
          investor: item[0] ? item[0].business_name : "",
          province: item[1] ? item[1][0] : "",
          area: item[2] ? item[2][0] : "",
          partsModel: item[3] ? item[3].specs : "",
          followway: item[4] ? item[4].followway : "",
          icpmode: item[5] ? item[5].icpmode : "",
          baseprice: "",
          agenCode: agentCode,
          agenName: agentName,
        };
      });
      console.log(this.priceList, "priceLists数据");

      this.rowMergeArrs = rowMergeHandle(this.needMergeArr, this.priceList);
    },
    // 历史记录
    handelHistory() {
      this.$http.post("/priceEdition/getTimeList",{ agencode: this.$route.query.agentCode}).then((res) => {
        if (res.data.code === 200) {
          this.historyList = res.data.data;
          this.currentData = res.data.data[0];
          this.changeHistoryDate(this.currentData)
          this.historyDialog = true;
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      });
    },
    //切换历史记录
    changeHistoryDate(item) {
      this.currentData = item;
      const data = {
        createtime: item,
        agenCode: this.$route.query.agentCode,
      };
      this.$http.post("/priceEdition/queryTreeByTime", data).then((res) => {
        if (res.data.code === 200) {
          this.historyTableList = res.data.data;
        }
      });
    },
    //导出
    excelExport() {
      // this.$http.post("/priceEdition/exportExcel", {}).then((res) => {
      //   const a = document.createElement("a");
      //   a.href = res.data.data.data;
      //   a.download = "价格方案.xls";
      //   a.click();
      //   // 阻止默认事件
      // });
      // let params = {
      //   title: "价格方案",
      // };
      // let html = document.getElementById("pdf");
      // console.log(html, 246);
      // this.getPdf(html, params);
    },
    // 获取省份等
    async getAddress() {
      let res = await this.$http.post("AgentPlatform/region", {});
      this.agencyArea = res.data.data;
    },
    // 获取市
    getCity() {
      this.cityList = this.agencyAreaItem.children;
      this.countyList = [];
    },
    // 获取县
    getCounty() {
      console.log(this.cityItem);
      // this.countyList = this.cityItem.children
    },
    changeDate(val) {
      console.log(val);
      if (val) {
        this.effectivetime = val[0];
        this.expirationtime = val[1];
      } else {
        this.effectivetime = "";
        this.expirationtime = "";
      }
    },
    //查询保存数据
    getTableData() {
      this.$http
        .post("/priceEdition/queryListall", {
          agencode: this.$route.query.agentCode,
        })
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res.data.data, "查询成功");
            this.postTableData = res.data.data[0]; // 岗位信息
            this.FilingList = res.data.data[1]; // 项目备案模式
            this.InstallationList = res.data.data[2]; // 项目安装方式
            this.materialList = res.data.data[3]; // 物料信息
            this.ruleForm.description = res.data.data[4][0]
              ? res.data.data[4][0].description
              : ""; // 描述
            this.formScale.name = res.data.data[4][0]
              ? res.data.data[4][0].scalevalue
              : ""; // 报装完成款比例
            this.formScale.add = res.data.data[4][0]
              ? res.data.data[4][0].warranty
              : ""; // 质保金比例
            // 城市
            if (res.data.data[5][0]) {
              this.regionTages.push({
                agencyAreaOutList: [
                  res.data.data[5][0] ? res.data.data[5][0].province : "",
                ],
                cityItemOutList: [
                  res.data.data[5][0] ? res.data.data[5][0].city : "",
                ],
              });
            }

            // 笛卡儿积城市
            this.setB = this.regionTages.map((item) => item.agencyAreaOutList);
            this.setC = this.regionTages.map((item) => item.cityItemOutList);

            this.createTable();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
              duration: 2000,
            });
          }
        });
    },
    // 保存
    savePrice() {
      const agentCode = this.$route.query.agentCode;
      const agentName = this.$route.query.agentName;
      // 投资方
      const priceEditionEnterpriseList = this.postTableData.map((item) => {
        return { businessName: item.business_name, head: item.head };
      });
      //项目备案模式
      const priceEditionIcpList = this.FilingList.map((item) => {
        return { head: item.head, icpmode: item.icpmode };
      });
      //安装方式
      const priceEditionInstallationList = this.InstallationList.map((item) => {
        return { followway: item.followway };
      });
      // 物料信息
      const priceEditionMaterialList = this.materialList.map((item) => {
        return {
          specs: item.specs,
          productName: item.productName,
          brand: item.brand,
        };
      });
      // 保证金
      const priceEditionMessageList = [
        {
          description: this.ruleForm.description,
          scalevalue: this.formScale.name,
          warranty: this.formScale.add,
        },
      ];
      const priceEdtionRegionList = this.regionTages.map((item) => {
        console.log(item);
        return {
          province: item.agencyAreaOutList[0],
          city: item.cityItemOutList[0],
        };
      });
      //
      const data = {
        agentCode: agentCode,
        agentName: agentName,
        priceEditionEnterpriseList,
        priceEditionIcpList,
        priceEditionInstallationList,
        priceEditionMaterialList,
        priceEditionMessageList,
        priceEdtionRegionList,
      };
      this.$http.post("/priceEdition/queryListinsert", data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("保存成功");
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      });
    },
    //保存并生效
    saveTakeEffect() {
      if (!this.formScale.name) {
        return this.$message.error("报装完成款比例不能为空");
      }
      if (!this.formScale.add) {
        return this.$message.error("质保金不能为空");
      }
      const priceEditionList = this.priceList.map((item) => {
        return {
          ...item,
          status: 1,
          effectivetime: this.effectivetime,
          expirationtime: this.expirationtime,
        };
      });
      const data = {
        description: this.ruleForm.description,
        priceEditionList,
        bzList: [this.formScale.name, this.formScale.add],
      };
      this.$http.post("/priceEdition/insertBatchDemo", data).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("保存并生效成功");
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
            duration: 2000,
          });
        }
      });
      console.log(data);
    },
    // 项目所属子公司（投资方）
    addInvestor() {
      this.$refs.investorFef.init();
    },
    // 项目所属子公司（投资方）| 获取子组件传值
    getInvestor(val) {
      const filteredVal = val.filter((ele) => {
        return !this.postTableData.some(
          (item) =>
            item.business_name === ele.business_name && item.head === ele.head
        );
      });
      if (filteredVal.length == 0) {
        this.$message({
          type: "warning", // success error warning
          message: "投资方已存在",
          duration: 2000,
        });
      }
      this.postTableData.push(...filteredVal);
      this.createTable();
    },

    //  项目所属区域-确认
    confirmPost() {
      this.regionTages.push({
        agencyAreaOutList: [this.agencyAreaItem.name],
        cityItemOutList: [this.cityItem.name],
      });
      // localStorage.setItem("regionTages", JSON.stringify(this.regionTages));
      console.log(this.regionTages, "项目所属区域");
      // 省
      this.setB = this.regionTages.map((item) => item.agencyAreaOutList);
      this.setC = this.regionTages.map((item) => item.cityItemOutList);
      this.createTable();

      this.selectPostDialog = false;
    },
    //删除tag标签
    handleClose(tag) {
      this.regionTages.splice(this.regionTages.indexOf(tag), 1);
      // localStorage.setItem("regionTages", JSON.stringify(this.regionTages));
      this.createTable();
    },
    // 项目安装方式
    addInstallation() {
      this.$refs.modeRef.init();
    },
    // 项目安装方式| 获取子组件传值
    getInstallation(val) {
      const filteredVal = val.filter((ele) => {
        return !this.InstallationList.some(
          (item) => item.followway === ele.followway
        );
      });
      if (filteredVal.length == 0) {
        this.$message({
          type: "warning", // success error warning
          message: "安装方式已存在",
          duration: 2000,
        });
      }
      this.InstallationList.push(...filteredVal);
      // this.setE = val;

      this.createTable();
    },
    // 项目备案模式
    addFiling() {
      this.$refs.filingRef.init();
    },
    // 项目备案模式| 获取子组件传值
    getFiling(val) {
      const filteredVal = val.filter((ele) => {
        return !this.FilingList.some(
          (item) => item.head === ele.head && item.icpmode === ele.icpmode
        );
      });
      if (filteredVal.length == 0) {
        this.$message({
          type: "warning", // success error warning
          message: "备案模式已存在",
          duration: 2000,
        });
      }
      this.FilingList.push(...filteredVal);
      // this.setF = val;

      // localStorage.setItem("FilingList", JSON.stringify(this.FilingList));
      this.createTable();
    },
    // 物料信息
    addMaterial() {
      this.$refs.materialRef.init();
    },
    // 物料信息| 获取子组件传值
    getMaterial(val) {
      this.setD = val;
      const newArr = val.map((item) => {
        return {
          productName: item.product_name,
          specs: item.specs,
          brand: item.brand,
        };
      });
      const filteredVal = newArr.filter((ele) => {
        return !this.materialList.some(
          (item) =>
            item.brand === ele.brand &&
            item.productName === ele.productName &&
            item.specs === ele.specs
        );
      });
      if (filteredVal.length == 0) {
        this.$message({
          type: "warning", // success error warning
          message: "该物料已存在",
          duration: 2000,
        });
      }
      this.materialList.push(...filteredVal);
      // product_name
      // this.materialList.push(...newArr);
      // localStorage.setItem("materialList", JSON.stringify(this.setD));
      this.createTable();
    },
    // 删除
    async del(index, list) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        this[list].splice(index, 1);
        // localStorage.setItem(list, JSON.stringify(this[list]));
        this.createTable();
      }
    },
    // 表单提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          data.objectCode = JSON.parse(
            localStorage.getItem("userInfo")
          ).userInfo.objectCode;
          data.objectName = JSON.parse(
            localStorage.getItem("userInfo")
          ).userInfo.objectName;
          data.loginName = data.phone;
          // data.loginName = data.loginName
          data.creator = this.userName;
          data.updator = this.userName;
          data.userType = "1";
          data.roleUserList = this.roleTableData;
          if (this.postTableData.length == 0) {
            this.$message.info("请选择与岗位的关系");
            return;
          }
          data.postName = this.postTableData[0].postName;
          data.postDesc = this.postTableData[0].description;
          // data.password = this.$md5("123456");
          if (this.orgTableData.length == 0) {
            this.$message.info("请选择与组织的关系");
            return;
          }
          data.deptCode = this.orgTableData[0].deptCode;
          data.deptName = this.orgTableData[0].deptName;
          let url = this.id ? "/userInfo/update" : "/userInfo/add";
          let res = await this.$http.post(url, data);
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "增加成功",
              showClose: true,
            });
            this.$router.push({
              path: "/employee",
            });
          } else {
            this.$message.error(res.data.message);
          }
        } else {
          return false;
        }
      });
    },
    setselectOrgDialog() {
      this.selectOrgDialog = true;
      this.$nextTick(() => {
        if (this.orgTableData.length == 0) {
          this.$refs.tree.setCheckedKeys([]);
        } else {
          this.$refs.tree.setCheckedKeys([this.orgTableData[0].deptCode]);
          this.multipleSelection = this.$refs.tree.getCheckedNodes();
        }
      });
    },
    setselectPostDialog() {
      this.selectPostDialog = true;
      this.getPostInfoData();
      // this.getAddress();
    },
    treeNodeClick(node, list) {
      this.parentCode = node.parentCode;
      this.multipleSelection = [node];
      this.$refs.tree.setCheckedKeys([node.deptCode]);
    },
    editTalk(row) {
      this.talkType = "edit";
      for (let i in this.talkForm) {
        this.talkForm[i] = row[i];
      }
      let img = row.annexUrl.split(",").filter((item) => item != "");
      this.fileList = img.map((item) => {
        return { name: item, url: item };
      });
      console.log(this.fileList, "fileList");
      this.talkDialog = true;
    },
    // async getTalkTable() {
    //   let res = await this.$http.post("talk/list", this.talkQueryInfo);
    //   console.log(res, "sstalkQueryInfos");
    //   if (res.data.code == 200) {
    //     this.talkTable = res.data.data.data;
    //     this.talkTotal = res.data.data.count;
    //   }
    // },
    async saveTalk() {
      let data = JSON.parse(JSON.stringify(this.talkForm));
      data.userName = this.ruleForm.name;
      data.annexUrl = this.fileList.map((item) => item.url).join(",");

      data.userCode = this.$route.query.userCode;
      console.log(data);
      if (this.talkType == "add") {
        delete data.id;
      }
      let res = await this.$http.post("talk/save", data);
      if (res.data.code == 200) {
        this.$message.success("保存成功");
        this.talkDialog = false;
        this.fileList = [];
        // this.getTalkTable();
      }
    },
    handleSuccess(res, file) {
      this.fileList.push({ name: res.data.url, url: res.data.url });
    },
    handleRemove(res, fileList) {
      let index = this.fileList.findIndex(
        (item) => item.url == res.response.data.url
      );
      this.fileList.splice(index, 1);
      console.log(res, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleInner = true;
    },
    addTalk() {
      (this.talkForm = {
        id: "",
        userCode: "",
        reward: "",
        talkor: "",
        content: "",
        talkDt: "",
      }),
        (this.talkType = "add");
      this.talkDialog = true;
    },
    setAuth() {
      this.selectAuthorityDialog = true;
      this.getRoleInfo();
    },
    goAnchor(index) {
      this.$nextTick(() => {
        document.querySelector("#a" + (index + 1)).scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start",
        });
      });
    },
    handleScroll() {
      // console.log(document.querySelector(".menu-content").scrollTop)
    },

    // 监听排序
    sortChange() {},

    //头像
    errorHandler() {
      return true;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

    // 与组织的关系删除事件
    async delOrg(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http.delete("/userOrgan/" + id).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "成功删除1条数据",
            showClose: true,
          });
        });
      }
    },

    // 与岗位的关系删除事件
    async delPost(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http.delete("/userPost/" + id).then(function (response) {
          that.$notify.success({
            title: "提示",
            message: "成功删除1条数据",
            showClose: true,
          });
          that.getPostData(that.userCode);
        });
      }
    },

    // 与权限的关系删除事件
    async delRole(id) {
      var that = this;
      const confirmResult = await that
        .$confirm("确定删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .catch((err) => err);
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== "confirm") {
        return that.$notify.info({
          title: "提示",
          message: "已取消删除",
          showClose: true,
        });
      } else {
        that.$http
          .post("engineeringRoleUser/delete", { id })
          .then(function (response) {
            that.$notify.success({
              title: "提示",
              message: "成功删除1条数据",
              showClose: true,
            });
            that.getRoleTableData();
          });
      }
    },

    //动态合并表格
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let res in this.needMergeArr) {
        if (this.needMergeArr[res] == column.property) {
          return this.mergeAction(column.property, rowIndex, columnIndex);
        }
      }
    },
    mergeAction(val, rowIndex) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },

    // 清空
    empty() {
      this.multipleSelection = [];
      this.$refs.tree.setCheckedKeys([]);
    },
    // //删除
    // del(id, i) {
    //   this.$refs.tree.setChecked(id);
    //   this.multipleSelection.splice(i, 1);
    // },
    // 组织选择确认
    confirmOrg() {
      this.orgTableData = this.multipleSelection.map((item) => {
        return { deptCode: item.deptCode, deptName: item.deptName };
      });
      this.selectOrgDialog = false;
    },

    // 选择
    handleCheckedItemsChange(value) {
      let multipleSelect = {
        agencyName: this.agencyAreaItem.name,
        cityName: this.cityItem.name,
        countyName: this.countyItem.name,
      };
      this.multipleSelection2 = [multipleSelect];
    },
    // 清空选择的岗位信息
    emptyPostInfo() {
      this.multipleSelection2 = [];
      this.checkAll = false;
      this.checkedItems = [];
    },
    // 删除选择的岗位信息
    delPostInfo(postName, i) {
      this.checkedItems = this.checkedItems.filter((item, i) => {
        if (item != postName) {
          return true;
        } else {
          return false;
        }
      });
      this.multipleSelection2.splice(i, 1);
      this.getPostInfoData();
    },
    // 监听 pagesize改变的事件
    handleSizeChangePost(newSize) {
      // console.log(newSize)
      this.queryInfoPost.size = newSize;
      this.getPostInfoData();
    },
    // 监听 页码值 改变事件
    handleCurrentChangePOst(newSize) {
      // console.log(newSize)
      this.queryInfoPost.page = newSize;
      this.getPostInfoData();
    },

    // 选择权限确认
    async confirmAuthority() {
      this.roleTableData.push(...this.multipleSelection3);
      this.selectAuthorityDialog = false;
    },
    // 监听 pagesize改变的事件
    handleSizeChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.pageSize = newSize;
      this.getRoleInfo();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeAuthority(newSize) {
      // console.log(newSize)
      this.queryInfoAuthority.currPage = newSize;
      this.getRoleInfo();
    },
    handleSizeChangeTalk(newSize) {
      this.queryInfoTalk.pageSize = newSize;
      this.getTalkData();
    },
    handleCurrentChangeTalk(newSize) {
      this.queryInfoTalk.currPage = newSize;
      this.getTalkData();
    },

    // 根据id获取用户信息
    async getDataById() {
      if (this.id != undefined || this.id != "") {
        let res = await this.$http.post("/userInfo/queryOne", { id: this.id });
        if (res.data.code == 200) {
          this.postTableData = [
            {
              postName: res.data.data.postName,
              description: res.data.data.postDesc,
            },
          ];
          this.talkForm.userCode = this.$route.query.userCode;
          this.talkQueryInfo.condition.useCode = res.data.data.useCode;
          let { deptCode, deptName } = res.data.data;
          this.orgTableData = [{ deptCode, deptName }];
          for (let i in this.ruleForm) {
            this.ruleForm[i] = res.data.data[i];
          }
        } else {
          this.$message.error(res.data.message);
        }
      }
    },

    // 获取树形结构数据
    getTreeData() {
      var that = this;
      that.treeData = [];
      that.$http
        .post("/engineeringDept/queryTreeList", {
          groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
            .engineeringGroup.groupCode,
        })
        .then(function (response) {
          //console.log(response);
          if (response.data.code == 200) {
            that.$nextTick(() => {
              that.defaultId = response.data.data[0].organCode;
            });
            that.treeData = response.data.data;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取岗位信息数据
    async getPostInfoData() {
      let res = await this.$http.post("post/queryList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        deptCode: this.parentCode,
      });
      if (res.data.code == 200) {
        this.selectPostData = res.data.data;
        // this.totlePost = res.data.data.count;
      } else {
        this.$message({
          message: res.data.message,
          duration: 1500,
          type: "error",
        });
      }
    },

    // 获取权限信息数据
    getRoleInfo() {
      var that = this;
      that.$http
        .post("AgentPlatform/PermissionsLsit", {})
        .then(function (response) {
          if (response.data.code == 200) {
            that.selectAuthorityData = response.data.data;
            // let arr = [];
            // that.roleTableData.forEach((row) => {
            //   that.selectAuthorityData.forEach((item) => {
            //     if (row.roleCode == item.roleCode) {
            //       arr.push(item);
            //     }
            //   });
            // });
            // setTimeout(() => {
            //   arr.forEach((row) => {
            //     that.$refs.multipleTable.toggleRowSelection(row, true);
            //   });
            // }, 400);
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取与权限的关系表格数据
    getRoleTableData() {
      var that = this;
      that.roleTableData = [];
      // console.log(that.userCode)
      that.$http
        .post("engineeringRoleUser/queryListByUser", {
          userCode: that.userCode,
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.roleTableData = response.data.data;
          } else {
            that.$message({
              message: response.data.message,
              duration: 1500,
              type: "error",
            });
          }
        });
    },

    // 获取随机字符串
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
  },
};
</script>

<style lang="less" scoped>
.h50 {
  height: 50px;
}

.el-button.add-column-btn {
  font-size: 13px;
  background-color: transparent;
  color: #008eff;
  margin-top: -1px;
  position: relative;
  z-index: 100;
}

.employeeDetail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }

  .avatar-uploader {
    top: -16px;
    right: 20px;
    z-index: 10;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;

      li {
        height: 50px;
        line-height: 50px;

        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }

      li.active,
      li:hover {
        background-color: #fff;
      }
    }

    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;

      .p1 {
        margin-bottom: 20px;
      }

      .header-search {
        .title {
          // height: 50px;
          font-weight: 700;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;

          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }

          .el-input {
            width: 247px;
          }

          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }

      .header-input {
        font-size: 13px;
      }

      .quyu {
        margin-top: 10px;
        font-size: 16px;
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        padding: 20px;

        .shengfen {
          margin: 20px 0 0 5px;
        }

        .shiqu {
          margin: 20px 0 0 5px;
        }
      }

      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }

        .el-table {
          margin-top: 10px;
        }
      }
    }
  }

  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }

  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }

  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
    overflow: auto;
  }
}
.dlqBox {
  height: 500px;
  overflow: auto;
  border-right: 1px solid #e3eaf0;
  padding-left: 25px;
  box-sizing: border-box;
}
.dlqBox-title {
  color: #22abee;
  margin: 0 0 10px 10px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-col {
  .el-upload-list__item {
    transition: none !important;
  }

  .el-upload--picture-card {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  height: 100px;
  width: 100px;
}

/* 自定义选中状态下的图标颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  //变成对号
  content: "\2713";
  /* Unicode编码的勾号 */
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  background-color: #007bff;
  /* 勾号的颜色 */
  font-size: 10px;
  /* 勾号的大小 */
  line-height: 12px;
  border-radius: 0;
}
::v-deep .el-radio__inner {
  border-radius: 0;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: transparent !important;
}

.history {
  display: flex;

  .left {
    width: 20%;
    border: 1px solid #e3eaf0;
    .Datebox {
      height: 40px;
      text-align: center;
      line-height: 22px;
      padding: 10px;
      box-sizing: border-box;
    }
    .active {
      background-color: #c7ebfc;
      color: #17adf1;
    }
  }
  .right {
    width: 80%;
    .el-table {
      margin-top: 0 !important;
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
}
::v-deep .el-dialog__title {
  margin-left: -9px !important;
}

.dialoghistory {
  height: 600px;
}
</style>
