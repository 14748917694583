<template>
    <div class="box">
        <el-dialog title="选择权限" :visible.sync="drawer" class="selectorDialog">
            <div class="search-condition">
                <el-row type="flex" justify="end">
                    <el-col :span="16">
                        <el-input placeholder="描述/权限组" class="mr10">
                            <el-button slot="append" icon="el-icon-search"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
            </div>

            <div class="org-selected">
                <el-row>
                    <el-col :span="12">
                        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark"
                            style="width: 100%; margin-top: 0" @selection-change="handleSelectionChange" border fit
                            :height="354" highlight-current-row>
                            <el-table-column type="selection" width="55" align="center">
                            </el-table-column>
                            <el-table-column prop="business_name" label="公司名称" align="center">
                            </el-table-column>
                            <el-table-column prop="head" label="负责人" align="center">
                            </el-table-column>
                        </el-table>
                        <!-- 分页 -->
                        <!-- <el-pagination @size-change="handleSizeChangeAuthority"
                            @current-change="handleCurrentChangeAuthority" :current-page="queryInfoAuthority.currPage"
                            :page-size="queryInfoAuthority.pageSize" layout="total, prev, pager, next"
                            :total="totleAuthority" class="ml10" style="margin-bottom: 15px"></el-pagination> -->
                    </el-col>
                    <el-col :span="12" class="selectedUl pr10 bbox">
                        <p class="wp100 bbox flex justify-between textblue">
                            <span>已选择</span>
                            <span class="pointer" @click="emptyAuthority"><i class="el-icon-delete"></i> 清空</span>
                        </p>
                        <div class="selectedUl-box">
                            <ul>
                                <li class="clearfix" v-for="(item, i) in multipleSelection" :key="item.id">
                                    <span class="floatLeft rightOrderBox">{{
                                        item.business_name
                                    }}</span>
                                    <i class="el-icon-close floatRight iconOrganization iconblue fw700 f15"
                                        @click="delAuthority(item.id, i)"></i>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="confirmAuthority">确 认</el-button>
                <el-button @click="drawer = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            drawer: false,
            tableData: [],

            queryInfoAuthority: {
                // 当前页数
                currPage: 1,
                // 每页显示多少数据
                pageSize: 100,
            },
            totleAuthority: 20,

            multipleSelection: [],
        };
    },
    methods: {
        init() {
            this.drawer = true
            this.getAddress()
        },
        // 选中的数据
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 清空
        emptyAuthority() {
            this.multipleSelection = [];
            this.$refs.multipleTable.clearSelection();
        },
        //删除
        delAuthority(id, i) {
            console.log(id, 'kkkkkkkk')
            this.tableData.forEach((row) => {
                if (row.id == id) {
                    this.$refs.multipleTable.toggleRowSelection(row, false);
                }
            });
            // this.multipleSelection.splice(i, 1);
        },
        // 监听 pagesize改变的事件
        handleSizeChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.pageSize = newSize;
            // this.getRoleInfo();
        },
        // 监听 页码值 改变事件
        handleCurrentChangeAuthority(newSize) {
            // console.log(newSize)
            this.queryInfoAuthority.currPage = newSize;
            // this.getRoleInfo();
        },

        // 获取列表数据
        async getAddress() {
            let res = await this.$http.post('PricePlans/enterpriselist', {});
            this.tableData = res.data.data
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        confirmAuthority() {
            this.drawer = false
            this.$emit('toInvestor',this.multipleSelection)
        },
    }
};
</script>
<style lang="less" scoped>
.box {
    width: 70%;
    .rightOrderBox {
        width: 80%;
    }
    // /deep/ .el-form-item {
    //     margin-bottom: 16px !important;
    // }
}
</style>
